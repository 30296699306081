import Image from "@/shared/components/Image/new"
import styles from "./styles.module.css";
import { loader } from "@/shared/functions/getImagesFunctions";
import { formatAuthorNames } from '@/shared/functions/formatAthorNames';
import { EnVivo } from "@/shared/components/enVivo";
import VideoLoop from "@/shared/components/VideoLoop";

const NotaAperturaBullet = (props) => {
    const { title, author, summary, url, images, subtitle, hasBackground, metadata, croppedImages = [], videosOpenLoop = {}} = props;
    let authorsList = []
    if (author != undefined && author?.length) {
        const authorName = formatAuthorNames(author[0].name);
        author.length > 1 ? author.slice(0, 2).map(element => {
            
            const elementName = formatAuthorNames(element.name);
            return authorsList.push(element.slug ? `<a href=/autor/${element.slug}>${elementName}</a>` : elementName)
            
        }) 
        
        : authorsList.push(author[0].slug ? `<a href=/autor/${author[0].slug}>${authorName}</a>` : authorName)
    }
    const parsedAuthors = authorsList.join(' y ');
    
    const noTagsHtml = summary && summary.length && summary.replace(/<[^>]+>/g, '').replace(/&nbsp[;]?/ig, ' ');
    

    const MAIN_CROP = '3_2_300x200';
    const SECONDARY_CROP = '3_2_300x200';
    
   	const manualClippingMobile = croppedImages && croppedImages.filter(el => el.id === "Mobile")?.[0]


	const manualClippingMobileUrl = manualClippingMobile?.url ?? null;


	const manualClippingDesktop = croppedImages && croppedImages.filter(el => el.id === "Desktop")?.[0]
 	const defaultImage = `${process.env.NEXT_PUBLIC_IMAGE_DOMAIN}/img/default.png`
	const imageUrl = images?.[0] && `${manualClippingDesktop ? manualClippingDesktop?.url : images[0]?.url}`
	const imageTitle = images?.[0] && images[0]?.title
	
    let imagenMobile = SECONDARY_CROP; //uso el cropeo que genera lila por defecto
	if (manualClippingMobileUrl) {
		imagenMobile = manualClippingMobileUrl; //si hay recorte uso el recorte
	} 
    if (title === undefined || title==="") return ("");
    else
    return ( 
        <li>
            <a href={url} className={styles["nota-container"]}>   
                      
                        {subtitle != "" && (
                            <span 
                                className={`${styles["blue-txt"]}`} 
                                dangerouslySetInnerHTML={{ __html: subtitle }} 
                            />
                        )}
        
                        {title != "" && (
                            <span 
                                className={`${styles["nota-title"]}`} 
                                dangerouslySetInnerHTML={{ __html: title}} 
                            />
                        )}
                     
            </a>
        </li>
               
    );
};
export default NotaAperturaBullet;
